import React from 'react';
import ReactDOM from 'react-dom/client';
import {BaselayoutV2} from './baselayoutv2'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
////  <React.StrictMode>
    <BaselayoutV2/>
//  </React.StrictMode>
);
