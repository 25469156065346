import React from "react";
import { Logincontext } from "../../baselayoutv2";
import ReturningMemberEmailVerification from "./ReturningMemberEmailVerification";
import addbase from "../../ref";
import Form_PL from "../../FormStructure/Form_Preload";
import ProfilePhotoUpload from "../Generics/ProfilePhotoUpload";

const ReturningMember = () => {
    const LC = React.useContext(Logincontext)

    const [formdata, setformdata] = React.useState(null);

    async function getformdata(){
        await fetch (addbase + "getregschema", {
            method: "GET",
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
            setformdata(data)
        })
        .catch((error)=>{
            alert("Network error")
        })
    }

    React.useEffect(() => {getformdata()}, [])

    async function submit_student_data(data){
        console.log(data)
        console.log(Object.values(data))
        await fetch(addbase + "setregstatus", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "id-type": "udid",
                "id": LC.uid,
                "stage": LC.step
            },
            body: JSON.stringify({
                id: {
                    email: LC.email,
                    udid: LC.uid,
                    type: LC.layoutmode,
                    stagelock: LC.steplim.toString()
                },
                data: Object.values(data)[0]
            })
        })
        if (LC.step > LC.stepcomplim) LC.setcomplim(LC.step);
    }

    async function parse_postverify_data(data) {
        console.log(data)

        await submit_student_data(data);

        LC.setstep(LC.step + 1);
    }

    const entryarray = React.useMemo( () => {
        if (formdata === null) return;
        return {
            0 : <ReturningMemberEmailVerification next={()=> {LC.setlim(8); LC.setstep(6)}} previous = {() => LC.reset_to_default()}/>,
            6 : <Form_PL key = {7} sections={{"stage6" : formdata.stage6}} previous = {() => LC.setstep(0)} next = {parse_postverify_data} step={6}/>,
            7 : <ProfilePhotoUpload next = {parse_postverify_data} previous = {() => LC.setstep(LC.step - 1)}/>
        }
    }, [formdata, LC.step]) 


    return <div className="center">
        {formdata !== null && entryarray[LC.step]}
    </div>
}

export default ReturningMember;
