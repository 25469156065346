import React from 'react'
import { useFormContext, formState} from 'react-hook-form'
import "./FormQuestionGeneric.css"

const FormQuestionSelect = React.memo((props) => {
    const {register, unregister, setValue, getValues} = props.methods

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setValue(name, value);
    }

    const add_validations = (field) => {
        let tmp = {};
        if ('custom_validation' in field) {
          Object.keys((field.custom_validation)).forEach((key) => {
            tmp[key] = (v) => calc_custom_validation(field.custom_validation[key]);
          })
        }
        //console.log(tmp);
       // console.log(Object.assign(field.validation, {validate: tmp}))
        return Object.assign(field.validation, {validate: tmp})
    }

    const calc_custom_validation = (entries) => {
        let bool_state = false;
        switch (entries.operation){
          case 'equals':
            bool_state = getValues(entries.target) === entries.targetvalue;
            break;
        }
        //console.log(bool_state)
        if (bool_state) return entries.errortext;
      }

    const renderSelectOptions = (refname) => {
      //console.log(refname)
        return (
            <option 
                key={refname[1]}
                value={refname[1]}
            >{refname[1]}</option>
        );
    }

    React.useEffect(() => {
      //console.log("USEEFFECT")
      if (props.disabled === true) unregister(props.ID)
    }, [props.disabled])

    const state = React.useMemo(() => {
      return props.disabled
    }, [props.disabled])

    //console.log(state)
    return (
      <div>
        <select className = 'textbox boxcolor' {...register(props.ID, props.data.validation)}>
          {Object.entries(props.data.options).map((b) => renderSelectOptions(b))}
        </select>
      </div>
    )
}) 
/*        {!state && <input className="textbox boxcolor" type={props.data.type} name={props.ID} {...register(props.ID, add_validations(props.data))} onChange={handleInputChange}/>}
        {state &&<input className="textbox boxcolor" type={props.data.type} disabled = {props.disabled} name={props.ID}/>}*/ 
export default FormQuestionSelect