import React from "react";
import { Logincontext } from "../../baselayoutv2";
import { useForm } from "react-hook-form";
import addbase from "../../ref";
import { ErrorMessage } from "@hookform/error-message";

/*
    email_submit_callback: callback upon submitting request
    validation: additional validation parameters
*/
const GenericEmailVerif = (props) => {
    const LC = React.useContext(Logincontext);

    const [verificationstate, setvstate] = React.useState('No Email Sent');

    const modifyemail = useForm({
        defaultValues: props.defaultValues
    });
    const verifyemail = useForm();

    const emailvalidation = {
        required: 'Email is required',
        pattern: {
          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
          message: 'Invalid email address',
        },
    }

    function parse_email_modify(data){
        LC.setemail(String(data.email).toLowerCase().trim());
        if (props.email_submit_callback) props.email_submit_callback(data);
        send_auto_email_request(String(data.email).toLowerCase().trim())
    }


    function send_auto_email_request (email) {
        setvstate("sending email")
        fetch(addbase + "requestverify", {
            method: "GET",
            headers: {
                "email": email
            },
        })
        .then((res) => {
      //      console.log(res)
            if (res.status === 200){
                setvstate("email sent")
            } else {
                setvstate("email failed to send")
            }
        })
        .catch((error)=>{
            setvstate("network error")
        })
    }

    function check_auto_email_request(data) {
        if (LC.email === null) setvstate("no email address specified")

        fetch(addbase + "verify", {
            method: "GET",
            headers: {
                "email": LC.email,
                "code": data.vcode
            }
        })
        .then((res) => {
            console.log(res)
            return res.json()
        }).then((data) => {
           console.log(data)
            if (data.valid === true){
                setvstate("valid code")
                LC.setuid(data.udid); //per spec, the generic element only gets stuff required for other requests. Grabbing other data is responsibility of parent component
                LC.setemail(String(data.email).toLowerCase().trim());
                props.next(data.udid, data.email); //this is bad, the context should be used but the context update functions dont update during the callback
                //thus, we just prop drill
            } else {
                setvstate("invalid code")
            }
        })
        .catch((error)=>{
            console.log(error)
            setvstate("network error")
        })
    }

    return <div>
            <div>Status: {verificationstate}</div>
            <div className = 'center wrap'>
                <form className='column center halve' onSubmit={modifyemail.handleSubmit(parse_email_modify)}>
                            <div>Email Address</div>
                            <div className='center'>
                                <input className='boxcolor halvedtextbox' id="newemail" {...modifyemail.register('email', Object.assign({}, emailvalidation, props.validation))}></input>
                                <button className='stepbutton' type="submit">Submit</button>
                            </div>
                </form>
                <form className='column center halve'  onSubmit = {verifyemail.handleSubmit(check_auto_email_request)}>
                            <div>Verification Code: </div>
                            <div className='center'>
                                <input className='boxcolor halvedtextbox' type="text" {...verifyemail.register('vcode', {required: true})}></input>
                                <button className='stepbutton' type="submit">Submit</button>
                            </div>
                </form>
            </div>
            <ErrorMessage
                errors={modifyemail.formState['errors']}
                name="email"
                render={({ message }) => <p>{message}</p>}
            />
    </div>
}

export default GenericEmailVerif;