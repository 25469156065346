import React from 'react'
import "./ElementStyle.css"
const FormElementHeader = (props) => {
    return (
        <div className = 'form-header'>
            <div className={'form-header-title-' + props.depthlevel}>{props.format.title}</div>
            <div>{props.format.description}</div>
        </div>
    )
}

export default FormElementHeader