import React from "react";
import { useForm } from "react-hook-form";
import FileUploadElement from "./FileUploadElement";
import '../../StartPage.css'
import addbase from "../../ref";
import { Logincontext } from "../../baselayoutv2";
import Form_PL from "../../FormStructure/Form_Preload";

//hardcoded due to multiple file uploads
//integrating this into formstructure would be tetious, one would have to conduct a bunch of hidden backend calls on form submission to upload everything to S3
//then you'd need to get the uploaded file name to replace the entry in the file
const VolunteerWaiverFormV2 = (props) => { 
    const form = useForm();
    const LC = React.useContext(Logincontext)

    const vformdata = `● I understand that CYC has a volunteer screening process in place, and that I may be asked to
undergo an orientation, interview, reference check, and /or criminal records check depending
on the nature of the volunteer position for which I am applying.
● I recognize that all CYC events and services are smoke, tobacco and drug free. In compliance
with Ontario and Municipal laws, I will abstain from smoking or using drugs while volunteering
for the CYC.
● I grant permission to the CYC to use my name, any photo or video images of me and any
comments made by me in writing or otherwise, for promotional purposes in any form of media
(i.e. TV, radio, web or print).
● I understand that confidentiality is fundamental to all programs of the CYC. Volunteers are
responsible for maintaining the confidentiality of all proprietary or privileged information to
which they are exposed while serving as a volunteer.
● I understand that I am representing the CYC during my time volunteering and I agree to act in
a professional manner at all times.
● I hereby release and discharge the CYC, its agents, employees and licensees from any claim
or action that I may have with respect to the use of any of the above or my participation in any
related CYC activities, while volunteering.`;

    const vformformatted = <>
        <div className="subtext">This agreement forms part of and must be attached to the Volunteer Application Form. Before you start volunteering, the Canadian Youth Champions (CYC) requires your agreement on the following:</div>
        <div className='subtext'>   
            {vformdata.split('\n').map((d) => <div key={d}>{d}</div>)}
        </div>
    </>

    const ack = 'I acknowledge that the information provided is true and accurate and that I have read, understood, and will abide by the Volunteer Agreement above. I grant the CYC permission to contact the references listed on my application form and follow up on any information provided.'
    const formstructure = {
        format: {
          title: '3. Volunteer Waiver Form',
          description: vformformatted
        },
        type: "section",
        entries:{
            acknowledgement: {
                label: 'Waiver form Acknowledgement',
                subtext: ack,
                type: 'checkbox',
                validation: {
                    required: 'Acknowledgement is required'
                }
            },
            selfConsent: {
                label: 'Student Consent',
                subtext: '(required)',
                type: 'checkbox',
                validation: {
                  required: 'Consent is required',
                }
            },
            parentConsent: {
                label: 'Parent Consent',
                subtext: '(required)',
                type: 'checkbox',
                validation: {
                  required: 'Consent is required',
                }
            },
            consentDate: {
                label: 'Date',
                subtext: '(required)', 
                type: 'date',
                validation: {
                    required: "Date is required"
                }
            }
        },
    }
    

    return ( <div className='center'>
        <div className='colors outline column'>
            <Form_PL sections={{"stage3": formstructure}} previous = {props.previous} next = {props.next} step={3}/>
        </div>
    </div>
    )
}

export default VolunteerWaiverFormV2