import React from 'react'
import { useFormContext, formState} from 'react-hook-form'
import "./FormQuestionGeneric.css"

const FormQuestionRadio = React.memo((props) => {
    const {register, unregister, setValue, getValues} = props.methods

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setValue(name, value);
    }

    const add_validations = (field) => {
        let tmp = {};
        if ('custom_validation' in field) {
          Object.keys((field.custom_validation)).forEach((key) => {
            tmp[key] = (v) => calc_custom_validation(field.custom_validation[key]);
          })
        }
        //console.log(tmp);
       // console.log(Object.assign(field.validation, {validate: tmp}))
        return Object.assign(field.validation, {validate: tmp})
    }

    const calc_custom_validation = (entries) => {
        let bool_state = false;
        switch (entries.operation){
          case 'equals':
            bool_state = getValues(entries.target) === entries.targetvalue;
            break;
        }
        //console.log(bool_state)
        if (bool_state) return entries.errortext;
      }

    const renderRadioOptions = (key,refname,field) => {
        return (
        <div className='radiopad' key = {key + "." + refname}>
            <div>
                <label>{field.label}</label>
                <div>{field.subtext}</div>
            </div>
            <input 
                type='radio'
                name={key + "." + refname}
                value={refname}
                {...register(key, field.validation)}
            />
        </div>
        );
    }

    React.useEffect(() => {
      //console.log("USEEFFECT")
      if (props.disabled === true) unregister(props.ID)
    }, [props.disabled])

    const state = React.useMemo(() => {
      return props.disabled
    }, [props.disabled])

    //console.log(state)
    return (
      <div className = 'radiobox'>
        {Object.entries(props.data.options).map(([a,b]) => renderRadioOptions(props.ID,a,b))}
      </div>
    )
}, (prevprops, nextprops) => prevprops.methods.formState.isDirty === nextprops.methods.formState.isDirty ) 
/*        {!state && <input className="textbox boxcolor" type={props.data.type} name={props.ID} {...register(props.ID, add_validations(props.data))} onChange={handleInputChange}/>}
        {state &&<input className="textbox boxcolor" type={props.data.type} disabled = {props.disabled} name={props.ID}/>}*/ 
export default FormQuestionRadio