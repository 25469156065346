import React from "react";
import { Logincontext } from "../../baselayoutv2";
import GenericEmailVerif from "../Generics/GenericEmailVerif";
import '../../StartPage.css'

const NewUserLogin = (props) => {

    return (
    <div>
        <div className = 'title'>Log In Via Email</div>
        <div className = 'subtext'>
            <div>Fill in the email address used for your registration. </div>
            <div>Upon submitting the email address, a verification code will be sent to your email address.</div>
            <div>Enter the verification code to confirm your identity.</div>
        </div>
        <div className='center wrap'>
            <GenericEmailVerif next={props.next}/>
            <button className='nextstepbutton' onClick={props.previous}>Go Back</button>
        </div>
    </div>
    )
}

export default NewUserLogin