import React from 'react'
import FormElement from './FormElement'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import './FormStyle.css'
import addbase from '../ref'

//this entire thing is a prop drilling hell. should probably be rewritten and fixed
const Form = /*React.memo (*/(props) => {
    const [step, setStep] = React.useState(0);
   // console.log("FORM LOAD")
   // console.log(props)
    const methods = useForm({
        mode: 'onChange', 
        defaultValues: props.pl
    });
    const {handleSubmit, unregister, watch, formState: {submitCount}, fields, reset} = methods    



    let t = -1;
    const calcparams = (key, value) => {
        t = t + 1;
       // console.log([key, value])
        return (<section key = {"L" + t}>{step === t && (<FormElement disabled = {false} depthlevel = {(props.depthlevel !== undefined ? props.depthlevel : 0)} ID = {key} layout={value} methods = {methods}/>)}</section>)
    }

    
    const steps = Object.entries(props.sections).map(([key, value]) => calcparams(key, value))
 //   console.log(step)
    const increment = () => {
        if (step === steps.length-1){
            setStep(0)
        } else {
            setStep(step+1)
        }
    }
    const decrement = () => {
        if (step > 0){
            setStep(step-1)
        } else {
            props.callbackprevious()
        }
    }
    

    const submitData = (data) => {
        console.log(data)
        //unregister();
        props.callback(data);
    }

    const generateButtons = () => {
     //   console.log(step)
        return (

            <div className='buttonbar center1'>
                <div className='buttonframe'>
                    <button className={'buttons ' + ((step === 0 && !(props.callbackprevious !== undefined)) && 'disabledbutton ')} disabled={ (step === 0 && !(props.callbackprevious !== undefined))} type = "button" onClick={() => {decrement()}}>Previous</button>
                </div>    
                <div className='buttonframe'>
                    <button className={'buttons ' + (step === steps.length-1 && 'disabledbutton')} disabled={(step === steps.length-1 && 'disabledbutton')} type = "button" onClick={handleSubmit(increment)}>Next</button>
                </div>
            </div>
        )
    }
    const tmp = (<pre>
        {JSON.stringify(watch(), null, 2)}
    </pre>)
    return (
        <div className= "center1 border ">
            <FormProvider {...methods} >
                <form className = "form-light sizing-tablet round">
                    {steps}
                    {generateButtons()}
                    <div className='submit'>
                        {step === steps.length-1 && <button className = "buttons" type = "button" onClick={handleSubmit(submitData)}>Submit</button>}
                    </div>
                </form>
            </FormProvider>

        </div>
    )
/*
            <pre>
                {JSON.stringify(watch(),null, 2)}
            </pre>*/
}//)

export default Form