import React from "react";
import '../../StartPage.css'
import '../../home.css'

const LoginNotification = (props) => {
    return <div>
        <div className = 'title'>Important Application Notes</div>
        <div className = 'subtext'>
            <div>1. Your application is resumable. You may use the "continue registration" button in the main menu to resume an application after completion of the email verification step.</div>
            <div>Note: only submitted sections are saved</div>
            <div>2. The application process is conducted in stages - email notifications will be sent once you are approved to to subsequent stages</div>
        </div>
        <button className="stepbutton" onClick={() => props.next()}>Continue</button>
    </div>
}

export default LoginNotification