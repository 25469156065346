import React from "react";
import { Logincontext } from "../../baselayoutv2";
import GenericEmailVerif from "../Generics/GenericEmailVerif";
import '../../StartPage.css'

//this is still hardcoded, 
const NewUserEmailVerification = (props) => {
    const LC = React.useContext(Logincontext);

    React.useEffect(() => {
        LC.setemail(String(props.cached_data.stage1.email).toLowerCase().trim())
    }, [])

    //manually update response to match the requested email address
    function update_step1form(data){
        var copy = {...props.cached_data}
        copy.stage1.email = data.email
        props.update_cached_data(copy); //TBD: CHECK IF WORK
    }

    const emailvalidation = {
        pattern: {
          value: /^[\w-\.]+@((?!(gapps|ycdsb|yrdsb)).)*$/g,
          message: 'Cannot use school email address'
        }
    }


    return (
    <div className = 'center'>
        <div className = 'colors outline column'>
            <div className = 'title'>Verify your Email</div>
                <div className = 'subtext'>
                        For verification purposes, we have sent a code to your previously specified email address, {LC.email}.
                        This email address will be used for future status updates and inquiries. 

                        Please enter your code below:
                </div>
            <div className='center wrap'>
                {LC.email && <GenericEmailVerif email_submit_callback={update_step1form} validation={emailvalidation} next={props.next} defaultValues = {{email : LC.email}}/>}
                <button className='nextstepbutton' onClick={props.previous}>Go Back</button>
            </div>
        </div>
    </div>
    )
}

export default NewUserEmailVerification