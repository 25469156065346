import React from 'react'
import FormElement from './FormElement'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import './FormStyle.css'
import addbase from '../ref'
import Form from './Form'
import { Logincontext } from '../baselayoutv2'

const Form_PL = /*React.memo (*/(props) => {
    var [preload, setpreload] = React.useState(undefined);
    const LC = React.useContext(Logincontext)
    console.log(LC.uid)
    React.useEffect(() => {
        //get prefill if prefill exists and is allowed to view
        console.log(LC)

        if (LC.uid !== null && props.step < LC.steplim && props.step <= LC.stepcomplim) get_prefill()
        else setpreload({})
    }, [LC, props])

    async function get_prefill(){
        console.log("FETCH")
        await fetch(addbase + "getregstatus", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "id-type": "udid",
                "id": LC.uid,
                "stage": props.step
            }
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
            console.log(data)
            setpreload(data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    return (preload !== undefined) ? <Form pl={preload} sections = {props.sections}  callbackprevious = {props.previous} callback={props.next}> </Form> : <div>loading</div>
}
export default Form_PL