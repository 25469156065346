import React from "react";
import '../../StartPage.css'
import '../../home.css'

const InvalidNotification = (props) => {
    return <div>
        <div className = 'title'>No Existing Data on File</div>
        <div className = 'subtext'>
            <div>We have not found your email account in our database. Please submit a new registration.</div>
        </div>
        <button className="stepbutton" onClick={() => props.next()}>Continue</button>
    </div>
}

export default InvalidNotification