import React from "react";

import ContinuingNewUser from "./BaseLayoutFormat/ContinuingNewUser/ContinuingNewUser";
import NewUser from "./BaseLayoutFormat/NewUser/NewUser";
import ReturningMember from "./BaseLayoutFormat/ReturningMember/ReturningMember";
import Menu from "./BaseLayoutFormat/Menu";
import Completed from "./BaseLayoutFormat/Generics/Completed"
import "./home.css"



const Logincontext = React.createContext()


//partial rewrite with contexts as to prevent prop drilling hell
const BaselayoutV2 = () => {
   // /*
    const [step, setstep] = React.useState(0);
    const [email, setemail] = React.useState(null);
    const [uid, setuid] = React.useState(null);
    const [steplim, setlim] = React.useState(5);
    const [stepcomplim, setcomplim] = React.useState(0); //maximum step that was completed
    //*/
/*
    const [step, setstep] = React.useState(3); //current step being displayed
    const [email, setemail] = React.useState('ili310465@gmail.com');
    const [uid, setuid] = React.useState('7166cdc5-8943-4b62-9c78-9b520f3a91ab');
    const [steplim, setlim] = React.useState(5); //maximum allowed step
    const [stepcomplim, setcomplim] = React.useState(5); //maximum step that was completed
*/
    //states: returningmember, newuser
    const [usertype, setusertype] = React.useState('newuser'); //type of user
    
    //states: returningmember, newuserlogin, newuser, 
    const [layoutmode, setLayoutMode] = React.useState('menu'); //current layout type, district from usertype as also holds login layout

    function reset_to_default() {
        setstep(0);
        setemail(null);
        setuid(null);
        setlim(5);
        setcomplim(0);
        setLayoutMode('menu');
        setusertype('newuser')
    }

    function generate_layout_structure() {
        switch (layoutmode){
            case "menu": return <Menu/>
            case "returningmember": return <ReturningMember/>
            case "existinguserlogin": return <ContinuingNewUser/>
            case "newuser": return <NewUser/>
        }
    }

    return (
        <Logincontext.Provider value = {{step, setstep, email, setemail, uid, setuid, layoutmode, setLayoutMode, reset_to_default, steplim, setlim, stepcomplim, setcomplim, usertype, setusertype}}>
            {(steplim > step || step === 4204) && generate_layout_structure()}
            {(steplim <= step && step != 4204) && <Completed callback={reset_to_default}/>}
        </Logincontext.Provider>
    )

}

export {BaselayoutV2, Logincontext}