import React from "react";
import { useForm } from "react-hook-form";
import FileUploadElement from "./FileUploadElement";
import '../../StartPage.css'
import addbase from "../../ref";
import { Logincontext } from "../../baselayoutv2";


//hardcoded due to file uploads
//integrating this into formstructure would be tetious, one would have to conduct a bunch of hidden backend calls on form submission to upload everything to S3
//then you'd need to get the uploaded file name to replace the entry in the file
const ProfilePhotoUpload = (props) => { 
    const form = useForm();
    const LC = React.useContext(Logincontext)
    const imagefile = form.watch("image")

    const imageurl = React.useMemo(() => {
        if (imagefile !== undefined && imagefile.length > 0){
            return URL.createObjectURL(imagefile[0])
        }
        return null
    }, [imagefile])


//UNCHECKED
    async function conduct_form_submission(data){
        console.log(data)

        var dataset = {...data};
        dataset.image = await upload_image(data.image[0], "profilephoto")
        
        console.log(dataset)
        if (dataset.image !== null) props.next({stage7: dataset});
        else alert("Upload error, try again")
    }

    async function upload_image(blob, imagetype){
        var dlink = await get_upload_link(imagetype)
        if (!dlink) return null;
        await fetch(dlink, {
            method: "PUT",
            body: blob
        })
        return (dlink.split(".com/")[1].split("?")[0]).replace("%40", "@")

    }

    async function get_upload_link(imagetype){
        var uploadl = await fetch(addbase + "uploadimage", {
            method: "GET",
            headers: {
                "id-type": "udid",
                "id": LC.uid,
                "regtype": imagetype,
            }
        })
        .then((res) => {
            console.log(res)
            return res.json()
        }).then((data) => {
            return data.uploadlink
        })
        .catch((error)=>{
            console.log(error)
        })

        return uploadl
    }



    return ( <div className='center'>
        <div className='colors outline column'>
            <div>
                <div className='title'>7. Profile Customization</div>
                <div className="subtext">For the purposes of creating the CYC Lookbook at the end of the season, students are required to upload a portrait.</div>
            </div>
            <form onSubmit={form.handleSubmit(conduct_form_submission)} className="column spacing">
                <div className="">
                    <FileUploadElement register={form.register} title="Upload A Profile Photo" label="image"/>
                    {imageurl !== null && <img className='fixedsize' src={imageurl} alt="preview"/>}
                </div>
                <button className="stepbutton" type='submit'>Submit</button>
            </form>
        </div>
    </div>
    )
}

export default ProfilePhotoUpload