import React from "react";

const FileUploadElement = (props) => {
    return (
        <div>
            <div  className="subtext">{props.title}</div>
            <input className="subtext" type="file" accept=".bmp,.png,.jpg,.webp,.jpeg" {...props.register(props.label, {required: true})}></input>
        </div>
    )

}

export default FileUploadElement;