import React from "react";
import addbase from "../ref";
import "../StartPage.css";
import "./videorecord.css"
import Webcam from "./webcam";
import VideoUpload from "./videoupload";

import { Logincontext } from "../baselayoutv2";

const WebcamComponent = (props) => {
  const [file, setfile] = React.useState()
  const [mode, setmode] = React.useState('generate')
  const [recordplatform, setrecordplatform] = React.useState()
  const LC = React.useContext(Logincontext)

        async function get_video_link() {
            var uploadl = await fetch(addbase + "upload", {
                method: "GET",
                headers: {
                    "id-type": "udid",
                    "id": LC.uid,
                }
            })
            .then((res) => {
             //   console.log(res)
                return res.json()
            }).then((data) => {
               // console.log(data.uploadlink)
                return data.uploadlink
            })
            .catch((error)=>{
                //console.log(error)
            })
            return uploadl
        }

        async function verify_upload(itemid) {
            await fetch(addbase + "declareupload", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "id-type": "udid",
                    "id": LC.uid,
                },
                body: JSON.stringify({
                    idlink: itemid
                })
            }).then((res) => {
              if (res.status >= 400) {
                alert("File upload failed, please try again")
                setmode("generate")
              } else props.next()
            })
            .catch((error)=>{
                console.log(error)
            })
        }

        async function upload_video(link, blob) {
            await fetch(link, {
                method: "PUT",
                body: blob
            })
            .then((res) => {
     //           console.log(res)
            })
            .then(async () => {
              await verify_upload(link.split(".com/")[1].split("?")[0])
            })
            .catch((error)=>{
       //       console.log(error)
            })
        }

        async function setup_preview (blob) {
          setfile(blob)
          console.log(blob)
          setmode("previewing")
        }

        async function enact_upload (){
          if (file !== undefined){ 
            const vlink = await get_video_link()
            setmode("uploading")
            await upload_video(vlink, file)
          } else alert("No content to upload. Please try again.")
        }

        const description = `To complete the application, please submit a 2 min self-introduction that includes your full name, the school you go to, your passions, your strength and/or areas to improve.
Here is a quick guideline to help you put your best foot forward:
Please choose your top 3 of the following skills and rank them from best to worst.
Reflect carefully and answer the questions to the best of your ability.
There are no wrong answers!`
        const list = `Leadership
        Public speaking and presentation
        Communication & organization
        Teamwork
        Creativity
        Time management`


        //TBD: 7 questions
        const instructions = <div className="column spacing">
            {description.split('\n').map((d) => <div className="subtext" key={d}>{d}</div>)}
          <ul>
            {list.split('\n').map((d) => <li key={d}>{d}</li>)}
          </ul>
        </div>

        
        const disp = () => {
          var viddisp;
          switch (recordplatform){
            case "upload": {
              viddisp = <div>
                  <VideoUpload exit={(blob) => setup_preview(blob)} cancel = {()=>{setmode("generate"); setrecordplatform(undefined)}}/>
              </div>
              break
            }
            case "webcam": {
              viddisp = <Webcam exit={(blob) => setup_preview(blob)} cancel = {()=>{setmode("generate"); setrecordplatform(undefined)}}/>
              break
            }
            default: {
              viddisp = <div className="vid_width">
                <button className= "stepbutton" onClick={()=>setrecordplatform("webcam")}>Use Webcam</button>
                <button className= "stepbutton" onClick={()=>setrecordplatform("upload")}>Upload A Video File</button>
              </div>
            }
          }
          return <div className="vid_width"> {viddisp}</div>
        }

        const screeneval = () => {
          if (mode === "generate") return disp();
          if (mode === "uploading") return <div className="vid_width">Please wait. Your file is being uploaded.</div>
          return <div>
            <video className="vid_width" src={URL.createObjectURL(file)} controls></video>
            </div>
        }

        return (
          <div className='center'>
            <div className='colors outline column'>
              <div className='title'>4. Registration Video</div>
                {screeneval()}
                <div className="row">
                  <div className="halve center">{instructions}</div>
                  <div className="halve">
                    {mode !== 'generate' && <>
                    <button className="stepbutton" onClick={enact_upload}>Upload</button>
                    <button className="stepbutton" onClick={()=>{setmode("generate"); setrecordplatform(undefined)}}>Change Video</button>
                    </>
                    }
                </div>
              </div>
              <div className="row spacing">
                  <button className="stepbutton" onClick={props.previous}>Go Back</button>
                  {LC.steplim >= 7 && <button className="stepbutton" onClick={props.next}>{"Continue (Already Completed)"}</button>}
              </div>
            </div>
          </div>
        );
      };


export default WebcamComponent