import React from "react";
import RecordRTC, { MediaStreamRecorder, WebAssemblyRecorder } from "recordrtc";
import "./videorecord.css"

const Webcam = (props) => {
    const [stream, setstream] = React.useState()
    const [rec, setrec] = React.useState()
    const [failure, setfailure] = React.useState(false)
    const [barstate, setbarstate] = React.useState("idle")
    async function init(){
        const constraints = {
            audio: true,
            video: true,
            width: 1280,
            height: 720,
        }
        try {
        const st = await navigator.mediaDevices.getUserMedia(constraints, {video: true, audio: true})
        setstream(st);
        console.log(st)

        console.log()

        var optiontypes =  {
            type: 'video',
            recorderType: MediaStreamRecorder,
            mimeType: 'video/webm\;codecs=vp8,opus',
            audioBitsPerSecond: 640000,
            videoBitsPerSecond: 1200000,
            timeSlice: 15000,
        }

            setrec(new RecordRTC(st,optiontypes))
        } catch (err) {
            setfailure(true)
        }
    }
    

    React.useEffect(() => {
        return () => {
          // Cleanup function to stop the stream if the component unmounts
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }
        };
      }, [stream]);

    async function start(){
        rec.startRecording();
        setbarstate("recording")
    }

    async function stop() {
        rec.stopRecording(() => {
            props.exit(rec.getBlob())
        });
    }

    React.useEffect(() => {
        init()
    }, [])

    console.log(rec)

    const menubar = () => {
        return <div className="row">
            {barstate === "idle" && <button className="stepbutton" onClick={start}>Start Recording</button>}
            {barstate === "recording" && <button className="stepbutton" onClick={stop}>Stop Recording</button>}
        </div>
    }

    return <div>
        {rec !== undefined && <div>
            <video className="vid_width" ref={(video) => {if (video) {video.srcObject = stream; video.play()}}} muted={true} autoPlay></video>
            {menubar()}
            </div>
        } 
        {failure === true && <div>
            <div>Webcam Error. Please try another option</div>
            <button onClick={props.cancel}>Select Another Option</button>    
        </div>}
    </div>

}

export default Webcam