import React from "react";
import "../../StartPage.css"


const Completed = (props) => {
    return (
        <div className = 'center'>
            <div className = 'colors outline column'>
                <div className = 'title'>Thanks For Registering</div>
                <div className = 'subtext'>
                    You have completed all currently available registration forms. 
                    You will be contacted via email for further instructions after our team has reviewed your documents.
                </div>
                <button className="nextstepbutton" onClick = {() => {props.callback()}}>Start another registration</button>
            </div>
        </div>
    )
}

export default Completed