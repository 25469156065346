import React from "react";
import { useForm } from "react-hook-form";

const VideoUpload = (props) => {
    const {register, handleSubmit} = useForm()
    const [error, setError] = React.useState("")

    function parse_submit(data){
        if (data.fileupload[0].size >= 6e+7) setError("File is too big. Please try a smaller file")
        else props.exit(data.fileupload[0])
    }


    return <div className="Vid_Width">
        <form onSubmit={handleSubmit(parse_submit)}>
            <div>Maximum file size: 50Mb</div>
            <input type="file" accept=".mp4,.mkv,.webm" {...register("fileupload", {required: true})}></input>
            <div>{error}</div>
            <button type="submit">Submit</button>
        </form>
    </div>


}

export default VideoUpload