import React from 'react'
import { Form, useFormContext, useWatch } from 'react-hook-form'

import FormElementHeader from './FormElementHeader'
import FormQuestion from './FormQuestion'

import './ElementStyle.css'

//absolute spagetti code from trying for way too long to get the render calls down to a sensible number
//all of formstructure should be rewritten eventually but it's probably not gonna happen/.
const FormElement = React.memo( (props) => {
    let counter = 0
    const [disable, setdisable] = React.useState(false);
    const methods = props.methods;

    const get_element = /*React.useCallback (*/(ID, question) => {
        switch (question.type){
            case ("section"):
                counter++;
                return <FormElement disabled={disable || props.disabled} depthlevel = {props.depthlevel + 1} key={props.ID + '.' + ID} ID = {props.ID + '.' + ID} methods = {props.methods} layout = {question} ></FormElement>
            default:
                //return <div></div>
                return <FormQuestion disabled={disable || props.disabled} key={props.ID + '.' + ID} ID = {props.ID + '.' + ID} data={question} methods = {props.methods}></FormQuestion>
        }
    }//, [methods])

    const disable_checkbox = React.useMemo(() => {
        return ( 
        <div>
            Skip Optional Section
            <input type="checkbox" onChange={(e) => {setdisable(e.target.checked);}}></input>
        </div>)
    })
 //  console.log(props.ID + " " + disable + " " + props.disabled);
/* 
   const elements = React.useMemo(() => Object.entries(props.layout.entries).map(([key, value]) => get_element(key, value, disable || props.disabled)), 
    [props.disabled, disable, props])
//*/
    const elements = React.useMemo(() => Object.entries(props.layout.entries).map(([key, value]) => get_element(key, value)), [props.disabled, disable])
    //top level elements (i.e where the skip section selection is) shouldn't be disabled
    return (
        <div className = {"" + (props.disabled && "disabled")}> 
            <FormElementHeader format = {props.layout.format} depthlevel = {props.depthlevel}></FormElementHeader>
            {(props.layout.mandatory === false && props.disabled === false) && disable_checkbox}
            <div className={"elementlayout "}>
                {elements}
            </div>
        </div>
    )
}, (prevprops, nextprops) => {
    if (prevprops.methods.formState.isDirty !== nextprops.methods.formState.isDirty) return false
    if (prevprops.disabled != nextprops.disabled) return false
    return true
})

export default FormElement