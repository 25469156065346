import React from "react";
import NewUserLogin from "./NewUserLogin";
import LoginNotification from "../Generics/LoginNotification";
import InvalidNotification from "./InvalidUser";
import { Logincontext } from "../../baselayoutv2";
import addbase from "../../ref";
const ContinuingNewUser = () => {
    const [internalstate, setinternalstate] = React.useState(0);
    const LC = React.useContext(Logincontext)

    //this component is responsible for setting the user's current step and application type
    //the other components are just supposed to load and render the requested data.
    function complete_login(){
//        console.log("SUCCESS")
        get_user_progress();
    }

    function get_user_progress(){
        fetch(
            addbase + "getregstatus",
            {
                headers: {
                    "Content-Type": "application/json",
                    "id-type": "udid",
                    "id": LC.uid,
                    "stage": "type"
                }
            })
            .then((res) => {
                return res.json()
            }).then((data) => {
                console.log(data)
                if (Object.values(data).length === 0) {   
                    setinternalstate(2)
                    return;
                }
                LC.setlim(parseInt(data.stagelock))
                LC.setstep(parseInt(data.curstage))
                LC.setcomplim(parseInt(data.curstage))
                LC.setLayoutMode(data.type)
                LC.setusertype(data.type)
            })
            .catch((error)=>{
                alert("No user data found. If you're seeing this error, contact admin@thecyc.org")
        })
    }


    const statearr = [
        <NewUserLogin next={() => setinternalstate(1)} previous={LC.reset_to_default}/>,
        <LoginNotification next={complete_login} previous={() => setinternalstate(0)}/>,
        <InvalidNotification next={LC.reset_to_default}/>
    ]

    return <div className='center'>
        <div className='colors outline column'>
            {statearr[internalstate]}
        </div>
    </div>

}

export default ContinuingNewUser