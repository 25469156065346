import React from "react";
import { Logincontext } from "../../baselayoutv2";
import addbase from "../../ref";
import Form_PL from "../../FormStructure/Form_Preload";
import NewUserEmailVerification from "./NewUserEmailVerification";
import WebcamComponent from '../../video/videorecord';
import LoginNotification from "../Generics/LoginNotification";
import VolunteerWaiverForm from "../Generics/VolunteerWavierForm";
import VolunteerWaiverFormV2 from "../Generics/VolunteerWavierFormV2";
import ProfilePhotoUpload from "../Generics/ProfilePhotoUpload";
const NewUser = () => {
    const LC = React.useContext(Logincontext);
    const uiddata = React.useRef(LC);

    const [formdata, setformdata] = React.useState(null);
    const [formcache, setformcache] = React.useState({});

    async function getformdata(){
        await fetch (addbase + "getregschema", {
            method: "GET",
        })
        .then((res) => {
            return res.json()
        }).then((data) => {
            setformdata(data)
        })
        .catch((error)=>{
            alert("Network error")
        })
    }

    React.useEffect(() => {getformdata()}, [])

    function parse_preverify_data(data) {
        //case: already verified email
        if (LC.uid !== null) {
            parse_postverify_data(data);
            return;
        }
        var basis = {...formcache}
        basis[Object.keys(data)[0]] = Object.values(data)[0];
        setformcache(basis);
        if (LC.step === 1) {
            LC.setemail(String(basis[Object.keys(data)[0]].email).toLowerCase().trim());
        }
        LC.setstep(4204); //case: null uid, thus not a continuing user, thus, bring to verification page
    }
    
    async function submit_student_data(data){
        await fetch(addbase + "setregstatus", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "id-type": "udid",
                "id": LC.uid,
                "stage": LC.step
            },
            body: JSON.stringify({
                id: {
                    email: LC.email,
                    udid: LC.uid,
                    type: LC.usertype
                },
                data: Object.values(data)[0]
            })
        })
        if (LC.step > LC.stepcomplim) LC.setcomplim(LC.step);
    }

    async function submit_cached_data(uid, email){
        try {
            await fetch(addbase + "setregstatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "id-type": "udid",
                    "id": uid,
                    "stage": '1'
                },
                body: JSON.stringify({
                    id: {
                        email: email,
                        udid: uid,
                        stagelock: LC.steplim.toString(),
                        type: LC.layoutmode
                    },
                    data: formcache.stage1
                })
            }).then((res) => {
                console.log(res)
                if (res.status >= 400){
                    alert("upload error. please try again")
                    return;
                } else {
                    LC.setstep(2);
                    LC.setcomplim(2);
                }
            })
                //note: uid and email already set in the generic email verifier component

        } catch (err) {
            alert("upload error. please try again")
        }      
    }

    async function parse_postverify_data(data) {
        console.log(data)

        await submit_student_data(data);

        LC.setstep(LC.step + 1);
    }

//            3 : <VolunteerWaiverForm next={parse_postverify_data} previous = {() => LC.setstep(LC.step-1)}/>,

    console.log(LC.step)
    const entryarray = React.useMemo( () => {
        if (formdata === null) return;
        return {
            0 : (
                <div className='colors outline column'>
                    <LoginNotification next={() => LC.setstep(LC.step + 1)} previous={() => LC.reset_to_default()}/>
                </div>
            ),
            1 : <Form_PL key = {1} sections={{"stage1" : formdata.stage1}} previous = {() => LC.reset_to_default()} next = {parse_preverify_data} step={1}/>,
            4204 : <NewUserEmailVerification next={submit_cached_data} previous = {() => LC.setstep(1)} cached_data = {formcache} update_cached_data={setformcache}/>,
            2 : <Form_PL key = {2} sections={{"stage2" : formdata.stage2}} previous = {() => LC.setstep(LC.step - 1)} next = {parse_postverify_data} step={2}/>,
            3 : <VolunteerWaiverFormV2 previous = {() => LC.setstep(LC.step - 1)} next = {parse_postverify_data} />,
            4 : <WebcamComponent next={() => LC.setstep(LC.step + 1)} previous = {() => LC.setstep(LC.step-1)}/>,
            5 : <Form_PL key = {3} sections={{"stage5" : formdata.stage5}} previous = {() => LC.setstep(LC.step - 1)} next = {parse_postverify_data} step={5}/>,
            6 : <Form_PL key = {4} sections={{"stage6" : formdata.stage6}} previous = {() => LC.setstep(LC.step - 1)} next = {parse_postverify_data} step={6}/>,
            7 : <ProfilePhotoUpload next = {parse_postverify_data} previous = {() => LC.setstep(LC.step - 1)}/>
        }
    }, [formdata, LC.step]) 

    return <div className="center">
        {formdata !== null && entryarray[LC.step]}
    </div>
}

export default NewUser;