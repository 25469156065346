import React from "react";
import "../StartPage.css";

import { Logincontext } from "../baselayoutv2";

const Menu = (props) => {
    const LC = React.useContext(Logincontext);

    return (
        <div className='center'>
            <div className='colors outline column'>
                <div>
                        <div className='title'>CYC Registration Portal</div>
                        <div className='subtext'>   
                            Welcome to the CYC Registration Portal.
                        </div>
                        <button className="nextstepbutton" onClick={() => { LC.setLayoutMode("newuser"); LC.setusertype("newuser")}}>{'Start A New Registration (New Applicants)'}</button>
                        <button className="nextstepbutton" onClick={() => { LC.setLayoutMode("returningmember"); LC.setusertype("returningmember")}}>{'Start A New Registration (Returning Member)'}</button>
                        <button className="nextstepbutton" onClick={() => { LC.setLayoutMode("existinguserlogin")}}>Continue Your Registration</button>
                </div>
            </div>
        </div>
    )

}

export default Menu