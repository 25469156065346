import React from 'react'
import { useFormContext, formState} from 'react-hook-form'
import "./FormQuestionGeneric.css"

const FormQuestionGeneric = React.memo((props) => {
    const {register, unregister,  formState, setValue, getValues} = props.methods;

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setValue(name, value);
    }

    const add_validations = (field) => {
     //  let tmp = undefined
      // if ('custom_validation' in field) tmp = field.custom_valudation
     /*   if ('custom_validation' in field) {
          for (const [key, value] in Object.entries(field.custom_validation)){
            tmp[key] = (v) => calc_custom_validation(v, value);
          }
        }
        console.log(tmp);
        console.log(Object.assign(field.validation, {validate: tmp}))*/
        return Object.assign(field.validation)
    }

    const calc_custom_validation = (v, entries) => {
        let bool_state = false;
        switch (entries.operation){
          case 'equals':
            bool_state = getValues(entries.target) === entries.targetvalue;
            break;
          case 'restrict_time':
            var hour = new Date(v).getHours();
            var day = new Date(v).getDate();
            bool_state = entries.banned_dates.includes(day) || (hour < entries.min_hour || hour > entries.max_hour);
            console.log(hour)
            console.log(day)
            console.log(bool_state)
            break;
        }
        //console.log(bool_state)
        if (bool_state) return entries.errortext;
        return false
      }

      
    React.useEffect(() => {
      //console.log("USEEFFECT")
      if (props.disabled === true) unregister(props.ID)
    }, [props.disabled])

    const state = React.useMemo(() => {
      return props.disabled
    }, [props.disabled])

    //console.log(state)
    return (
      <div>
        {!state && <input className="textbox boxcolor" type={props.data.type} name={props.ID} {...register(props.ID, add_validations(props.data))} onChange={handleInputChange}/>}
        {state &&<input className="textbox boxcolor" type={props.data.type} disabled = {props.disabled} name={props.ID}/>}
      </div>
    )

})

export default FormQuestionGeneric