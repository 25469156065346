import React from "react";
import { Logincontext } from "../../baselayoutv2";
import GenericEmailVerif from "../Generics/GenericEmailVerif";
import addbase from "../../ref";
import '../../StartPage.css'
import { useForm } from "react-hook-form";

//this is still hardcoded, 
const ReturningMemberEmailVerification = (props) => {
    const LC = React.useContext(Logincontext);

    const emailvalidation = {
        pattern: {
          value: /^[A-Za-z0-9._%+-]+@thecyc\.org$/g,
          message: 'Must use a CYC email address'
        }
    }

    function submit_findname_request(udid, email){
        fetch(
            addbase + 'findmember',
            {
                method: "GET",
                headers: {
                    "id-type": "email",
                    "id": email.trim().toLowerCase(),
                }
            }
        ).then((res) => {
            console.log(res)
            if (res.status === 404) {
                alert("No member with the specified email was found. Contact the CYC team if you are sure you were a prior member.")
                return;
            }
            if (res.status >= 400){
                alert("Unable to verify if email was previously used. Please try again.")
                return;
            }
            props.next();
        })
    }


    return (
    <div className = 'center'>
        <div className = 'colors outline column'>
            <div className = 'title'>Verify your Membership</div>
                <div className = 'subtext'>
                        To verify your previous membership, an email code will be sent to your prior CYC email address. This address must be from or after the W23 season.
                </div>
            <div className='center wrap'>
                <GenericEmailVerif next={submit_findname_request} validation={emailvalidation}/>
                <button className='nextstepbutton' onClick={props.previous}>Go Back</button>
            </div>
        </div>
    </div>
    )
}

export default ReturningMemberEmailVerification