import React from 'react'
import { useFormContext, formState} from 'react-hook-form'
import "./FormQuestionGeneric.css"

const FormQuestionSelectOptional = React.memo((props) => {
    const {register, unregister, setValue, getValues, watch} = props.methods
    const [dstate, setdstate] = React.useState(false)
    const renderSelectOptions = (refname) => {
      //console.log(refname)
        return (
            <option 
                key={refname[1]}
                value={refname[1]}
            >{refname[1]}</option>
        );
    }

    React.useEffect(() => {
      //console.log("USEEFFECT")
   //   if (props.disabled === true) unregister(props.ID)
    }, [props.disabled])

    const state = React.useMemo(() => {
      return props.disabled
    }, [props.disabled])


    const selo = React.useMemo(() => {
      return <>
        <select className = 'textbox boxcolor' value={watch(props.ID)} style={{width: "40%"}} disabled = {props.disabled || dstate} onChange={(e) => {
          setValue(props.ID, e.target.value, {
            shouldValidate: true,
            shouldDirty: true
          })
        }}>
          {Object.entries(props.data.options).map((b) => renderSelectOptions(b))}
        </select>
        <div style={{width: "40%"}}>
          <div>
            <div>Select Other Option</div>
            <input type='checkbox' onChange={(e) => {setdstate(e.target.checked)}}/>
          </div>
          <input className = 'textbox boxcolor' {...register(props.ID, props.data.validation)} placeholder='Enter your other option here' hidden={!dstate || props.disabled}/>
        </div>
      </>
    }, [dstate, props])

    return (
      <div className='row' style={{gap: "20px"}}>
          {selo}

      </div>
    )
}) 
/*        {!state && <input className="textbox boxcolor" type={props.data.type} name={props.ID} {...register(props.ID, add_validations(props.data))} onChange={handleInputChange}/>}
        {state &&<input className="textbox boxcolor" type={props.data.type} disabled = {props.disabled} name={props.ID}/>}*/ 
export default FormQuestionSelectOptional